<template>
  <div>
    <!-- lets see if this works -->
    <v-img src="@/assets/sample_background.jpg">
      <v-container class="mt-15">
        <v-container class="fill-height mt-5" fluid>
          <!-- GUEST LOGIN -->
          <div class="ma-auto">
            <v-card class="elevation-12 rounded-l pa-5" max-width="">
              <v-card-text>
                <div v-if="!admin_login" class="text-center">
                  <v-avatar class="mb-5" size="65">
                    <img src="@/assets/jmbcircle.png" alt="JMB"
                  /></v-avatar>
                  <h2>JMBComputers</h2>
                  <p class="mt-n1 overline">Online Portal</p>
                  <v-divider class="mt-2 mb-2"></v-divider>
                  <p>Please enter your code below to login</p>
                  <v-text-field
                    v-model="customer_code"
                    label="code"
                    prepend-icon="mdi-key"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="customer_login"
                    :loading="loading"
                    >Login</v-btn
                  >
                  <v-btn
                    color="warning"
                    class="ml-2"
                    @click="admin_login = true"
                    >Admin Login</v-btn
                  >
                  <p class="caption grey--text text--lighten-1 mt-5">
                    Powered by: JMBComputers | Version {{ packageJson.version }}
                  </p>
                </div>

                <!-- ADMIN LOGIN -->
                <div class="text-center" v-else>
                  <v-avatar class="mb-5" size="90">
                    <img src="@/assets/jmbcircle.png" alt="JMB"
                  /></v-avatar>
                  <h2>Admin Login</h2>
                  <!-- <h1 class="grey--text">Admin Login</h1> -->
                  <br />
                  <v-form ref="form">
                    <v-text-field
                      prepend-icon="mdi-account-outline"
                      label="Username"
                      placeholder="Enter your username"
                      v-model="form.username"
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      prepend-icon="mdi-lock-outline"
                      label="Password"
                      placeholder="Enter your password"
                      v-model="form.password"
                    ></v-text-field>
                  </v-form>
                  <br />
                  <v-btn
                    elevation="2"
                    color="black"
                    class="mr-2 white--text"
                    @click="submit()"
                    :loading="loading"
                    >Login</v-btn
                  >
                  <v-btn elevation="2" color="" @click="reset">Reset</v-btn>

                  <v-divider class="mt-2"></v-divider>
                  <p class="caption grey--text text--lighten-1 mt-5">
                    Powered by: JMBComputers | Version {{ packageJson.version }}
                  </p>
                </div></v-card-text
              >
            </v-card>
          </div>
        </v-container>
      </v-container>
      <notification />
    </v-img>
  </div>
</template>

<script>
import packageJson from "../../package.json";
import { mapActions } from "vuex";
import Notification from "../components/Notification.vue";
export default {
  components: {
    Notification,
  },
  data() {
    return {
      packageJson: packageJson,
      customer_code: "",
      admin_login: true,
      form: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    customer_login() {
      this.loading = true;
      this.get_customer(this.customer_code)
        .then(() => {
          this.loading = false;
          this.set_notification({
            message: "Sucessfully logged in",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/customerDash");
          }, 1000);
        })
        .catch((err) => {
          let err_message = "";
          if (err.response && err.response.status === 404) {
            err_message = "No accounts found";
          } else if (err.response && err.response.status === 500) {
            err_message = "The portal is not in service right now";
          } else {
            err_message = "An error occurred";
          }
          this.loading = false;
          this.set_notification({ message: err_message, type: "error" });
        });
    },

    ...mapActions({
      get_customer: "customer/get_customer",
      signin: "auth/signin",
      set_notification: "notification/set_notification",
    }),
    reset() {
      (this.form.username = ""), (this.form.password = "");
    },
    submit() {
      this.loading = true;
      this.signin(this.form)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.set_notification({
              message: "Username or Password Incorrect. Please try again.",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            });
          } else if (err.response.status == 404) {
            this.set_notification({
              message: "Account disabled",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            });
          } else {
            this.set_notification({
              message: "Something went wrong. Please contact the administrator",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
